import React from "react"
import { Observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import Table from "./Table"

export default ({ products }) => (
	<Table
		defaultSorted={[
			{ id: "isInStore", desc: true },
			{ id: "name", desc: false }
		]}
		data={products}
		columns={[
			{
				Header: "Product",
				accessor: "name",
				Cell: props => (
					<Observer>
						{() => (
							<Link to={`/product/${props.original.uid}`}>
								{props.value}
							</Link>
						)}
					</Observer>
				)
			},
			{
				Header: "Stock left",
				id: "stockLeft",
				accessor: d =>
					new Proxy(d, {
						get: obj => {
							return () =>
								obj.stock
									.map(s => s.stock)
									.reduce((a, b) => a + b)
						}
					}),
				Cell: props => (
					<Observer>
						{() => {
							const stockLeft = props.original.stock
								.map(s => s.stock)
								.reduce((a, b) => a + b)

							return stockLeft ? (
								stockLeft
							) : (
								<span className="soldOut">Sold out</span>
							)
						}}
					</Observer>
				)
			},
			{
				Header: "In shop",
				id: "isInStore",
				accessor: "isInStore",
				Cell: props => (
					<Observer>
						{() => (
							<span
								className={
									props.original.isInStore
										? "inStore"
										: "notInStore"
								}
							>
								{props.original.isInStore ? "Yes" : "No"}
							</span>
						)}
					</Observer>
				)
			}
		]}
	/>
)
