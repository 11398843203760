import React, { useState, Fragment } from "react"
import { Button, Collapse } from "@blueprintjs/core"
import Prism from "./Prism"

export default ({ payload, language = "json" }) => {
	const [isOpen, setIsOpen] = useState(false)
	const toggleDeploy = () => setIsOpen(!isOpen)

	return (
		<Fragment>
			<Button onClick={toggleDeploy}>
				{isOpen ? "Hide details" : "Show details"}
			</Button>
			<Collapse isOpen={isOpen} keepChildrenMounted>
				<Prism code={payload} language={language} />
			</Collapse>
		</Fragment>
	)
}
