import { observable, action } from "mobx"

export default class CustomersStore {
	constructor(api) {
		this.api = api
	}

	@observable customers = []

	get haveCustomers() {
		return Array.isArray(this.customers) && this.customers.length
			? true
			: false
	}

	findCustomer(id, keyOnly = false) {
		const find = customer => customer.id && customer.id === id

		if (this.haveCustomers)
			if (keyOnly) return this.customers.findIndex(find)
			else return this.customers.find(find)

		return false
	}

	getCustomer(id, force = false) {
		const cachedCustomer = this.findCustomer(id)
		if (cachedCustomer && !force) return Promise.resolve(cachedCustomer)

		return new Promise((resolve, reject) => {
			this.getCustomers(force)
				.then(() => {
					resolve(this.findCustomer(id))
				})
				.catch(reject)
		})
	}

	getCustomers(force = false) {
		if (!force && this.haveCustomers) return Promise.resolve(this.customers)

		return this.api
			.get(`/customers`)
			.then(response => {
				this.setCustomers(response.data.items)
				return response.data.items
			})
			.catch(() => false)
	}

	@action
	setCustomers(customers) {
		if (Array.isArray(customers))
			customers.forEach(customers => this.setCustomer(customers))
	}

	@action
	setCustomer(customer) {
		if (!customer.id) return

		const updateCustomer = this.findCustomer(customer.id, true)

		if (updateCustomer !== false && updateCustomer > -1)
			this.customers[updateCustomer] = customer
		else if (Array.isArray(this.customers)) this.customers.push(customer)
	}
}
