import React, { useContext } from "react"
import { Navbar, Button, Alignment, Tooltip, Position } from "@blueprintjs/core"
import { NavLink } from "react-router-dom"
import { Observer } from "mobx-react-lite"
import { Context } from "../Auth"
import { Container, Row, Col } from "./Grid"
import logo from "../static/images/black-corner-icon.png"

export default props => {
	const auth = useContext(Context)

	const UserProfile = props => {
		return (
			<div className="userAvatar hide-xs">
				<Observer>
					{() => (
						<Tooltip
							content={auth.userProfile.name}
							position={Position.BOTTOM}
						>
							<img
								src={auth.userProfile.picture}
								alt={auth.userProfile.nickname}
							/>
						</Tooltip>
					)}
				</Observer>
			</div>
		)
	}

	return (
		<Navbar>
			<Container>
				<Row>
					<Col size="12">
						<Navbar.Group align={Alignment.LEFT}>
							<Navbar.Heading>
								<NavLink exact to="/">
									<img src={logo} alt="Black Corner Logo" />
								</NavLink>
							</Navbar.Heading>

							<Navbar.Divider />

							<NavLink exact to="/" className="hide-xs">
								<Button
									className="bp3-minimal"
									icon="home"
									text="Home"
								/>
							</NavLink>

							<NavLink
								isActive={(match, location) => {
									return (
										location &&
										location.pathname &&
										location.pathname.startsWith("/product")
									)
								}}
								to="/products"
							>
								<Button
									className="bp3-minimal"
									icon="shop"
									text="Products"
								/>
							</NavLink>

							<NavLink
								isActive={(match, location) => {
									return (
										location &&
										location.pathname &&
										location.pathname.startsWith("/order")
									)
								}}
								to="/orders"
							>
								<Button
									className="bp3-minimal"
									icon="dollar"
									text="Orders"
								/>
							</NavLink>
						</Navbar.Group>

						<Navbar.Group align={Alignment.RIGHT}>
							<UserProfile />
							<Button
								className="bp3-minimal hide-xs"
								icon="log-out"
								text="Logout"
								onClick={auth.logout}
							/>
						</Navbar.Group>
					</Col>
				</Row>
			</Container>
		</Navbar>
	)
}
