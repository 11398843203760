import React, { Fragment, useEffect, useContext, createRef } from "react"
import { Observer } from "mobx-react-lite"
import { InputGroup, Tag } from "@blueprintjs/core"
import { Context } from "../Stores"
import { Container, Row, Col } from "../Components/Grid"

import { thousandSeparator } from "../utils"
import OrdersTable from "../Components/OrdersTable"

export default props => {
	const { OrdersStore: o, WebSocket: ws } = useContext(Context)

	const handleWSEvents = event => {
		if (event === "order" || event === "orderUpdate") o.getOrders(true)
	}

	useEffect(() => {
		o.getOrders(true)

		ws.on("event", handleWSEvents)
		return () => ws.removeListener("event", handleWSEvents)
	})

	// It's own component to trigger less renders of the table
	const SearchInput = ({
		resultsCount,
		onChange,
		initialValue,
		searchRef
	}) => (
		<InputGroup
			className="searchOrders"
			placeholder="Search orders..."
			leftIcon="filter"
			rightElement={
				<Tag minimal onClick={() => onChange(false)}>
					{resultsCount}
				</Tag>
			}
			onChange={onChange}
			defaultValue={initialValue}
			inputRef={searchRef}
			large
		/>
	)

	return (
		<Container>
			<Row>
				<Col size={12}>
					<Observer>
						{() => {
							// Persist search
							const storageKey = "ordersSearch"
							const initialValue =
								localStorage.getItem(storageKey) || ""
							const searchRef = createRef()

							// Reset or load filter on initial load
							useEffect(() => o.setSearchInput(initialValue), [
								initialValue
							])

							// Dont render anything until we have observable orders
							if (!Array.isArray(o.orders) || !o.orders.length)
								return (
									<h1>
										Orders <span>Loading...</span>
									</h1>
								)

							// The mulah made
							const totalSoldWorth = o.orders
								.filter(
									s =>
										typeof s.status === "string" &&
										s.status.toLowerCase() !== "cancelled"
								)
								.map(s => s.finalGrandTotal)
								.reduce((a, b) => a + b)

							// Filter
							let filterTimer = false
							const searchOrders = ev => {
								// Clear eryting lil blahd
								if (ev === false) {
									localStorage.removeItem(storageKey)
									o.setSearchInput(false)
									searchRef.current.value = ""
									return
								}

								clearTimeout(filterTimer)

								const input = ev.target.value

								localStorage.setItem(storageKey, input)
								filterTimer = setTimeout(() => {
									o.setSearchInput(input)
								}, 250)
							}

							return (
								<Fragment>
									<h1>
										Orders
										<span>
											{`We sold for ${thousandSeparator(
												totalSoldWorth
											)} € worth of stuff`}
										</span>
									</h1>

									<SearchInput
										resultsCount={o.filteredOrders.length}
										onChange={searchOrders}
										initialValue={initialValue}
										searchRef={searchRef}
									/>

									<OrdersTable orders={o.filteredOrders} />
								</Fragment>
							)
						}}
					</Observer>
				</Col>
			</Row>
		</Container>
	)
}
