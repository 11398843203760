import { createContext } from "react"
import { auth } from "../Auth"
import ProductsStore from "./products"
import OrdersStore from "./orders"
import CustomersStore from "./customers"
import WebSocketStore from "./websocket"

const stores = {
	ProductsStore: new ProductsStore(auth.api),
	OrdersStore: new OrdersStore(auth.api),
	CustomersStore: new CustomersStore(auth.api),
	WebSocket: new WebSocketStore(process.env.REACT_APP_API_URL)
}

const Context = createContext(stores)
const { Provider, Consumer } = Context

export { Provider, Consumer, Context, stores }
