import React, { Fragment, useEffect, useContext } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { FocusStyleManager } from "@blueprintjs/core"

import Nav from "./Components/Nav"
import Home from "./Pages/Home"
import Products from "./Pages/Products"
import Product from "./Pages/Product"
import Orders from "./Pages/Orders"
import Order from "./Pages/Order"
import Customer from "./Pages/Customer"
// import HandleRedirect from "./Components/HandleRedirect"
import {
	Context as AuthContext,
	CrossOriginVerification,
	AuthedComponent
} from "./Auth"
import { Context } from "./Stores"

import "./static/styles/app.scss"

FocusStyleManager.onlyShowFocusOnTabs()

export default props => {
	const auth = useContext(AuthContext)
	const { WebSocket: ws } = useContext(Context)

	ws.on("open", () => {
		// if we're logged in, subscribe to admin events
		if (auth.isAuthenticated) ws.subscribeAdmin(auth.idToken)
	})

	useEffect(() => {
		// Clear that shit - see HandleRedirect (WIP)
		localStorage.removeItem("redirectURL")

		// Display login popup if not already logged in
		auth.requireAuth()
	})

	return (
		<Router>
			<Fragment>
				{/* <HandleRedirect /> */}

				<AuthedComponent>
					<Nav />
				</AuthedComponent>

				<div id="appContentWrapper">
					<Switch>
						<Route
							exact
							path="/"
							component={props => (
								<AuthedComponent {...props}>
									<Home />
								</AuthedComponent>
							)}
						/>

						<Route
							path="/cov"
							component={CrossOriginVerification}
						/>

						<Route
							path="/products"
							component={props => (
								<AuthedComponent {...props}>
									<Products />
								</AuthedComponent>
							)}
						/>

						<Route
							path="/product/:uid"
							component={props => (
								<AuthedComponent {...props}>
									<Product />
								</AuthedComponent>
							)}
						/>

						<Route
							path="/orders"
							component={props => (
								<AuthedComponent {...props}>
									<Orders />
								</AuthedComponent>
							)}
						/>

						<Route
							path="/order/:token"
							component={props => (
								<AuthedComponent {...props}>
									<Order />
								</AuthedComponent>
							)}
						/>

						<Route
							path="/customer/:id"
							component={props => (
								<AuthedComponent {...props}>
									<Customer />
								</AuthedComponent>
							)}
						/>
					</Switch>
				</div>
			</Fragment>
		</Router>
	)
}
