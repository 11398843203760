import React from "react"
import { phoneNumber } from "../utils"

export default ({ number }) => {
	const render = (number, valid) => (
		<span
			className={["phoneNumber", valid ? "valid" : "invalid"].join(" ")}
		>
			{number}
		</span>
	)

	const parsed = phoneNumber(number)

	if (typeof parsed !== "object") return render(number, false)
	return render(parsed.formatInternational(), parsed.isValid())
}
