import React, { useEffect, useContext } from "react"
import { Observer } from "mobx-react-lite"
import Money from "../Components/Money"
import { Link } from "react-router-dom"
import CustomerAddress from "../Components/CustomerAddress"
import PhoneNumber from "../Components/PhoneNumber"
import TimeAgo from "../Components/TimeAgo"
import CustomerDetails from "../Components/DeployableCode"
import Table from "../Components/Table"
import { Container, Row, Col } from "../Components/Grid"
import { Context } from "../Stores"

export default props => {
	const { match } = props
	const { OrdersStore: o, CustomersStore: c } = useContext(Context)

	useEffect(() => {
		c.getCustomer(match.params.id, true)
		o.getOrders()
	}, [c, o, match.params.id])

	const CustomerTable = ({ customer }) => {
		return (
			<>
				<h3>
					<img
						className="customerAvatar"
						src={customer.gravatarUrl}
						alt={customer.shippingAddressName}
					/>
					{customer.shippingAddressName}
				</h3>
				<table>
					<thead>
						<tr>
							<th className="left"># of Orders</th>
							<th className="center">Amount spent</th>
							<th className="center">First seen</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{customer.statistics.ordersCount}</td>
							<td className="center">
								<Money
									amount={customer.statistics.ordersAmount}
								/>
							</td>
							<td>
								<TimeAgo
									date={customer.creationDate}
									showRealTime
								/>
							</td>
						</tr>
					</tbody>
					<thead>
						<tr>
							<th className="center">Shipping Address</th>
							<th className="center">Billing Address</th>
							<th className="center">Email</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="center">
								<CustomerAddress customer={customer} shipping />
								<br />
								<PhoneNumber
									number={customer.shippingAddressPhone}
								/>
							</td>
							<td className="center">
								<CustomerAddress customer={customer} billing />
								<br />
								<PhoneNumber
									number={customer.billingAddressPhone}
								/>
							</td>
							<td>{customer.email}</td>
						</tr>
					</tbody>
				</table>
			</>
		)
	}

	const Orders = ({ orders, customerID }) => {
		if (!orders || !orders.length || !customerID) return null

		return (
			<>
				<h3>
					{orders.length} {orders.length > 1 ? "Orders" : "Order"}
				</h3>
				<Table
					className="ordersTable"
					data={orders}
					defaultSorted={[{ id: "modificationDate", desc: true }]}
					columns={[
						{
							Header: "Spent",
							accessor: "finalGrandTotal",
							Cell: props => <Money amount={props.value} />
						},
						{
							Header: "# Items",
							accessor: "items",
							Cell: props => <span>{props.value.length}</span>
						},
						{
							Header: "Date",
							accessor: "modificationDate",
							Cell: props => <TimeAgo date={props.value} />
						},
						{
							Header: "Status",
							accessor: "status",
							Cell: props => (
								<span
									className={props.original.status.toLowerCase()}
								>
									{props.original.status}
								</span>
							)
						},
						{
							Header: "Orders",
							accessor: null,
							sortable: false,
							Cell: props => (
								<Link to={`/order/${props.original.token}`}>
									{props.original.invoiceNumber}
								</Link>
							)
						}
					]}
				/>
			</>
		)
	}

	return (
		<Observer>
			{() => {
				const customer = c.findCustomer(match.params.id)
				const orders = o.findOrderByCustomer(match.params.id)

				if (!customer) return null

				return (
					<Container className="customer">
						<Row>
							<Col size="12">
								<CustomerTable customer={customer} />
							</Col>
						</Row>

						<Row className="customerOrders">
							<Col size="12">
								<Orders
									orders={orders}
									customerID={match.params.id}
								/>
							</Col>
						</Row>

						<Row className="customerDetails">
							<Col size="12">
								<CustomerDetails payload={customer} />
							</Col>
						</Row>
					</Container>
				)
			}}
		</Observer>
	)
}
