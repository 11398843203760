import React, { Fragment } from "react"
import { getCountryName } from "../utils"

// order: the full order object
// customer: the customer object
// Shipping: wether to display shipping address
// Billing: wether to display billing address
// isOrder: will return order address, not customer's

// Passing only the order object is good enough
export default ({ order, customer, shipping, billing, isOrder = false }) => {
	if (!customer) customer = order.user

	const mapOrderWithUserFields = ({ order, customer }) => {
		if (!customer) customer = order.user

		const removeKeysPrefix = (object, prefix) => {
			const parsed = {}

			Object.keys(object)
				.filter(k => k.startsWith(prefix))
				.map(
					k =>
						(parsed[
							k
								.replace(new RegExp(`^${prefix}`), "")
								.slice(0, 1)
								.toLowerCase()
								.concat(k.slice(prefix.length + 1, k.length))
						] = object[k])
				)

			return parsed
		}

		const parsed = cleanData => {
			const {
				firstName,
				name,
				address1,
				address2,
				province,
				postalCode,
				city,
				country: c
			} = cleanData

			return {
				get fullName() {
					if (firstName && name) return `${firstName} ${name}`
					else if (!firstName && name) return `${name}`
					else if (firstName && !name) return `${firstName}`

					return undefined
				},
				get fullAddress() {
					if (address1 && address2)
						return (
							<Fragment>
								{address1}
								<span className="comment">{address2}</span>
							</Fragment>
						)
					else if (!address1 && address2) return `${address2}`
					else if (address1 && !address2) return `${address1}`

					return undefined
				},
				get province() {
					return province ? province : undefined
				},
				get postalCode() {
					return postalCode ? postalCode : undefined
				},
				get city() {
					return city ? city : undefined
				},
				get country() {
					return c ? c : undefined
				}
			}
		}

		return {
			orderShipping: parsed(
				removeKeysPrefix(order || customer, "shippingAddress")
			),
			orderBilling: parsed(
				removeKeysPrefix(order || customer, "billingAddress")
			),
			userShipping: customer.shippingAddress,
			userBilling: customer.billingAddress
		}
	}

	const render = add => (
		<div className="customerAddress">
			<p className="customerName">{add.fullName}</p>
			<p className="customerAddress">{add.fullAddress}</p>
			{add.province ? (
				<p className="customerProvince">{add.province}</p>
			) : null}
			<p className="customerCity">
				<span>{add.postalCode}</span>
				<span>{add.city}</span>
			</p>
			<p className="customerCountry">{getCountryName(add.country)}</p>
		</div>
	)

	const {
		orderShipping,
		orderBilling,
		userShipping,
		userBilling
	} = mapOrderWithUserFields({ order, customer })

	if (isOrder && !billing) return render(orderShipping)
	else if (isOrder && billing) return render(orderBilling)
	else if (!billing && !shipping) return render(userShipping)
	else if (billing && !shipping) return render(userBilling)
	else if (billing && shipping)
		return (
			<Fragment>
				{render(userShipping)} {render(userBilling)}
			</Fragment>
		)
	else return render(userShipping)
}
