// import Auth from "./auth0"
// import Store from "./store"
import { createContext } from "react"
import Auth from "./auth"
import CrossOriginVerification from "./crossOriginVerification"
import AuthedComponent from "./AuthedComponent"

const auth = new Auth()
// const store = new Store()
const Context = createContext(auth)

// export { auth, store, CrossOriginVerification, AuthedComponent }
export { auth, Context, CrossOriginVerification, AuthedComponent }
