import React, { useEffect, useContext, useState, useRef, memo } from "react"
import { Observer } from "mobx-react-lite"
import {
	Popover,
	Button,
	Classes,
	Card,
	NumericInput,
	Divider,
	Colors,
	Intent,
	NonIdealState,
	Position,
	Toaster
} from "@blueprintjs/core"
import { Context } from "../Stores"
import { Container, Row, Col } from "../Components/Grid"
import ProductDetails from "../Components/DeployableCode"

export default props => {
	const { match } = props
	const { ProductsStore: p, WebSocket: ws } = useContext(Context)
	const [hasError, setError] = useState(false)
	const isFirstLoad = useRef(true)
	const getProduct = (id, force = false) => {
		p.getProduct(id, force)
			.catch(() => {
				if (isFirstLoad.current) setError(true)
			})
			.finally(() => {
				if (isFirstLoad.current) isFirstLoad.current = false
			})
	}
	const handleWSEvents = event => {
		if (event === "stockUpdate") getProduct(match.params.uid, true)
	}

	useEffect(() => {
		getProduct(match.params.uid)

		ws.on("event", handleWSEvents)
		return () => ws.removeListener("event", handleWSEvents)

		/** NO NEED FOR POLLING SINCE WE HAVE WS - KEEPING IT JUST IN CASE.... */
		// const updateProduct = setInterval(() => {
		// 	getProduct(match.params.uid)
		// }, 5000)

		// return () => clearInterval(updateProduct)
	})

	// Product loaded but we got an error
	if (hasError)
		return (
			<Container>
				<Row>
					<Col size="12">
						<NonIdealState
							icon="zoom-out"
							title="Could not find this
					product"
							description="It seems that you've reached a page
					that doesn't exist Maybe this product isn't sold in the shop
					anymore ?"
							action={
								<Button
									text="Back"
									icon="step-backward"
									onClick={props.history.goBack}
								/>
							}
						/>
					</Col>
				</Row>
			</Container>
		)

	// The component that allows to edit stock
	const EditStock = memo(({ originalStock, id }) => {
		const [newStock, setNewStock] = useState(originalStock.stock)
		const [hasChanged, setHasChanged] = useState(false)
		const handleStockValueChange = newValue => {
			setNewStock(newValue)

			if (!hasChanged && newValue !== originalStock.stock)
				setHasChanged(true)
			else if (hasChanged && newValue === originalStock.stock)
				setHasChanged(false)
		}

		// I don't really know why, but hasChanged isn't reset when props change ...
		if (hasChanged && newStock === originalStock.stock) setHasChanged(false)

		return (
			<Card className="compact">
				<h6>Update stock</h6>
				<p>
					Size: <b>{originalStock.name}</b>
				</p>
				<NumericInput
					min={0}
					placeholder="Stock amount"
					leftIcon="numerical"
					minorStepSize={1}
					value={newStock}
					onValueChange={handleStockValueChange}
				/>
				<Divider />
				<div className="validateButtons">
					<Button
						text="Reset"
						icon="key-delete"
						disabled={!hasChanged}
						onClick={() =>
							handleStockValueChange(originalStock.stock)
						}
						intent={Intent.DANGER}
						small
					/>

					<ConfirmStockUpdate
						hasChanged={hasChanged}
						oldStock={originalStock.stock}
						newStock={newStock}
						size={originalStock.name}
						id={id}
					/>
				</div>
			</Card>
		)
	})

	// The component that prompts the user to confirm the update
	const ConfirmStockUpdate = memo(
		({ hasChanged, oldStock, newStock, size, id }) => {
			const [isLoading, setLoading] = useState(false)
			const [hasUpdated, setUpdated] = useState(false)

			// console.log(hasChanged, hasUpdated, isLoading)

			const fireUpdate = () => {
				const notification = Toaster.create({
					className: "stock-update-toaster",
					position: Position.TOP
				})

				setLoading(true)

				p.setStock(id, size, oldStock, newStock)
					.then(response => {
						setUpdated(true)

						// We got an error message from the API - Notify the user
						if (typeof response === "string")
							notification.show({
								message: response,
								intent: Intent.DANGER
							})

						// No error, but the product wasn't updated
						if (response === false)
							notification.show({
								message: "Stock hasn't changed",
								intent: Intent.WARNING
							})

						// We got the updated product
						if (typeof response === "object" && response.id) {
							setUpdated(false) // reset state
							notification.show({
								message: "Stock was updated successfully",
								intent: Intent.SUCCESS
							})
						}
					})
					.catch(err => {
						notification.show({
							message:
								typeof err === "string" ? err : err.message,
							intent: Intent.DANGER
						})
					})
					.finally(() => {
						setLoading(false)
					})
			}

			return (
				<Popover
					disabled={!hasChanged || hasUpdated}
					onClose={() => setUpdated(false)}
					captureDismiss
				>
					<Button
						text="Update"
						icon="updated"
						disabled={!hasChanged}
						intent={Intent.SUCCESS}
						small
					/>
					<Card className="compact">
						<h6>Are you sure ?</h6>
						<p style={{ maxWidth: 180 }}>
							Size: <b>{size}</b> <br />
							Old stock:{" "}
							<b style={{ color: Colors.VERMILION5 }}>
								{oldStock}
							</b>{" "}
							<br />
							New stock:{" "}
							<b style={{ color: Colors.GREEN5 }}>
								{newStock}
							</b>{" "}
							<br />
						</p>
						<Divider />
						<div className="validateButtons">
							<Button
								className={Classes.POPOVER_DISMISS}
								text="Hell no"
								icon="undo"
								disabled={!hasChanged}
								loading={isLoading}
								intent={Intent.DANGER}
								small
							/>

							<Button
								text="Oh yeah"
								icon="confirm"
								disabled={!hasChanged}
								intent={Intent.SUCCESS}
								loading={isLoading}
								onClick={fireUpdate}
								small
							/>
						</div>
					</Card>
				</Popover>
			)
		}
	)

	const Actions = ({ product }) => {
		const isProd = process.env.NODE_ENV === "development" ? false : true
		const shopUrl = isProd
			? "https://black-corner.com/shop"
			: "https://preview.black-corner.com/shop"
		const productCategory = product.full.data.type.slug

		return (
			<Row className="productActions">
				<Col size="12">
					<h5>Actions</h5>

					<div className="actionsList">
						<a
							href={`${shopUrl}/${productCategory}/${product.uid}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Button
								text="See in shop"
								icon="shop"
								intent="primary"
								disabled={!product.isInStore}
							/>
						</a>
					</div>
				</Col>
			</Row>
		)
	}

	// The main component / page
	return (
		<Observer>
			{() => {
				const product = p.product(match.params.uid)

				// Wait for the full object
				if (!product || !product.full) return null

				const stockLeft = product.stock
					.map(s => s.stock)
					.reduce((a, b) => a + b)

				return (
					<Container>
						<Row>
							<Col size="12">
								<h1>
									{product.name}{" "}
									<span>Stock - {stockLeft}</span>
								</h1>
							</Col>
							<Col xs="12" size="6">
								<table>
									<thead>
										<tr>
											<th>Size</th>
											<th>In stock</th>
											<th>Set Stock</th>
										</tr>
									</thead>
									<tbody>
										{product.stock.map((size, i) => (
											<tr key={i}>
												<td>{size.name}</td>
												<td>{size.stock}</td>
												<td>
													<Popover>
														<Button
															text="Edit"
															icon="edit"
															small
														/>
														<EditStock
															originalStock={size}
															id={product.id}
														/>
													</Popover>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Col>
							<Col xs="12" size="6">
								<Card>
									<div className="productSideView">
										<div className="imgWrapper">
											<img
												src={
													product.full.data
														.transparent_image.url
												}
												alt={product.uid}
											/>
										</div>
									</div>
								</Card>
							</Col>
						</Row>

						<Actions product={product} />

						<Row className="productDetails">
							<Col size="12">
								<ProductDetails payload={product} />
							</Col>
						</Row>
					</Container>
				)
			}}
		</Observer>
	)
}
