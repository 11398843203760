import Sockette from "sockette"
import EventEmitter from "events"
import { log } from "../utils"

const l = log("Store:WebSocket")

export default class WS extends EventEmitter {
	constructor(server, settings = { autoreconnect: true }) {
		super(server, settings)

		// Since we use the same server for the API & WS, let's only use one URL too
		if (server.startsWith("http:")) server = server.replace(/^http/, "ws")
		if (server.startsWith("https:"))
			server = server.replace(/^https/, "wss")

		this.on("error", l.debug)

		const self = this // Prevents calling the built-in special "error" event
		const emit = (name, data) => self.emit(name, data)

		this.ws = new Sockette(server, {
			timeout: 5000,
			maxAttempts: 10,
			onopen: e => emit("open", e),
			onreconnect: e => emit("reconnecting", e),
			onmaximum: e => emit("maximum", e),
			onclose: e => emit("close", e),
			onerror: e => emit("error", e),
			onmessage: e => {
				let msg = e.data
				if (e.data)
					try {
						msg = JSON.parse(e.data)
					} catch (err) {
						l.debug(err)
					}

				if (msg.event) emit("event", msg.event)
				else emit("message", msg)

				if (process.env.NODE_ENV === "development") console.log(msg)
			}
		})
	}

	send = data => {
		if (typeof data === "object") return this.ws.json(data)

		return this.ws.send(data)
	}
	json = data => this.ws.json(data)
	close = (code, reason) => this.ws.close(code, reason)
	reconnect = () => this.ws.reconnect()
	open = () => this.ws.open()

	subscribeAdmin = token => {
		return this.send({ event: "admin", token: token })
	}
}
