import Auth0Lock from "auth0-lock"
import config from "./config.json"
import { observable, action, comparer } from "mobx"
import { api as client, log } from "../utils"
import { isBoolean } from "util"

const l = log("Auth:AuthJS")

export default class Auth {
	@observable authenticated = false
	@observable userProfile = false
	@observable accessToken = false
	idToken = observable.box(false)

	@action
	setAuthenticated = state => {
		state = isBoolean(state) ? state : false
		if (this.authenticated !== state) this.authenticated = state
	}

	@action
	setUserProfile = user => {
		try {
			user = JSON.parse(user)
		} catch (err) {}

		if (!comparer.structural(this.userProfile, user))
			this.userProfile = user
	}

	@action
	setIdToken = idToken => {
		if (!comparer.default(this.idToken.get(), idToken))
			this.idToken.set(idToken)
	}

	@action
	setAccessToken = accessToken => {
		if (!comparer.default(this.accessToken, accessToken))
			this.accessToken = accessToken
	}

	lastUserCheck = false

	constructor() {
		this.baseURL = `${window.location.protocol}//${window.location.host}`

		// Load from storage
		this.rehydrate()

		this.lock = new Auth0Lock(config.clientID, config.domain, {
			auth: {
				responseType: config.responseType,
				params: { scope: config.scope },
				redirectUrl: this.baseURL
			},
			theme: {
				logo: "https://i.imgur.com/ddadTJI.png",
				primaryColor: "#000"
			},
			languageDictionary: {
				title: "Log in"
			},
			closable: false,
			allowedConnections: ["Username-Password-Authentication"]
		})

		// Handle Lock events
		this.lock.on("authenticated", this.handleLogin)
		this.lock.on("signin submit", this.handleSubmit)
		this.lock.on("signup submit", this.handleSubmit)
		this.lock.on("authorization_error", this.login)
		this.lock.on("unrecoverable_error", error => {
			l.error("unrecoverable_error", error)
			localStorage.removeItem("hasSubmitted")
		})

		// Observe token changes to invalidate the API client
		this.idToken.observe(() => {
			l.debug("Updating api client")
			this.apiClient = false
		}, true)

		// Subscribe to local storage edits - mostly debug
		// if (typeof window !== undefined)
		// 	window.addEventListener(
		// 		"storage",
		// 		event => {
		// 			if (event.storageArea === localStorage) {
		// 				if (event.key === "idToken")
		// 					this.setIdToken(event.newValue)
		// 			}
		// 		},
		// 		false
		// 	)
	}

	login = (error = false) => {
		localStorage.removeItem("hasSubmitted")

		let options = {}

		if (error) {
			this.setAuthenticated(false)

			options = {
				flashMessage: {
					type: "error",
					text: error.errorDescription || "UNKNOWN ERROR"
				}
			}
		}

		this.lock.show(options)
	}

	logout = () => {
		localStorage.removeItem("accessToken")
		localStorage.removeItem("profile")
		localStorage.removeItem("idToken")
		localStorage.removeItem("expiresAt")
		localStorage.removeItem("hasSubmitted")

		this.setAuthenticated(false)
		this.setUserProfile(false)
		this.setIdToken(false)

		this.lock.logout({ returnTo: this.baseURL })
	}

	isAuthenticated = () => {
		return new Promise(async resolve => {
			const accessToken = localStorage.getItem("accessToken")
			const idToken = localStorage.getItem("idToken")
			const profile = localStorage.getItem("profile")
			const expiresAt = localStorage.getItem("expiresAt")

			// Check that we have all the required shit
			if (!accessToken || !idToken || !profile || !expiresAt) {
				l.debug(
					"Not authenticated because missing some required values"
				)
				this.setAuthenticated(false)
				return resolve(false)
			}

			// Check that the sessions hasn't expired
			if (new Date().getTime() > parseInt(expiresAt)) {
				let sessionIsValid = await this.checkSession()
				if (sessionIsValid) {
					this.setAuthenticated(true)
					return resolve(true)
				}
				this.setAuthenticated(false)
				return resolve(false)
			}

			this.setAuthenticated(true)
			return resolve(true)
		})
	}

	requireAuth = () => {
		if (window.location.pathname !== "/cov")
			this.isAuthenticated().then(authed => {
				// Prevents stale submitted states
				let hasSubmitted =
					(localStorage.getItem("hasSubmitted") || 0) >=
					Date.now() - 5 * 1000

				if (!authed && !hasSubmitted) this.login()
			})
	}

	handleLogin = authResult => {
		localStorage.removeItem("hasSubmitted")

		this.getUserInfo(authResult.accessToken, (error, profile) => {
			if (error)
				if (error.code && error.code !== 429) {
					// This is because we've spammed this endpoint - ignore for now
					this.setAuthenticated(false)
					return l.error("Error handling login", error)
				}

			// We might not have a profile if we got a 429
			if (profile)
				localStorage.setItem("profile", JSON.stringify(profile))

			localStorage.setItem("accessToken", authResult.accessToken)
			localStorage.setItem("idToken", authResult.idToken)
			localStorage.setItem(
				"expiresAt",
				parseInt(authResult.expiresIn * 1000 + new Date().getTime())
			)

			this.setAccessToken(authResult.accessToken)
			this.setIdToken(authResult.idToken)
			this.setAuthenticated(true)

			this.lock.hide()
		})
	}

	getUserInfo = (accessToken, cb) => {
		if (
			this.lastUserCheck &&
			this.userProfile &&
			this.lastUserCheck >= Date.now() - 60 * 1000
		)
			return cb(null, this.userProfile)

		return this.lock.getUserInfo(accessToken, (err, profile) => {
			l.debug("Returning user info from server")

			if (!err) this.lastUserCheck = Date.now()
			if (profile) this.setUserProfile(profile)

			return cb(err, profile)
		})
	}

	checkSession = () => {
		return new Promise(resolve => {
			this.lock.checkSession({}, (err, authResult) => {
				if (err) return resolve(false)

				l.debug("Checked session successfully")

				this.handleLogin(authResult)

				return resolve(authResult)
			})
		})
	}

	handleSubmit = () => {
		// Remember the path for post-login redirection
		let currentPath = window.location.pathname
		if (currentPath !== "/" && currentPath !== "")
			localStorage.setItem("redirectURL", currentPath)
		else localStorage.removeItem("redirectURL")

		// Store the timestamp for when the form was submitted
		localStorage.setItem("hasSubmitted", Date.now())
	}

	rehydrate = () => {
		this.setAccessToken(localStorage.getItem("accessToken"))
		this.setUserProfile(localStorage.getItem("profile"))
		this.setIdToken(localStorage.getItem("idToken"))
	}

	// Making this reactive-ish - probably should be baked in the original method in utils
	apiClient = false
	api = new Proxy(
		{},
		{
			get: (obj, prop) => {
				if (this.apiClient) return this.apiClient[prop]

				const clientInstance = client({
					apiURL: process.env.REACT_APP_API_URL,
					token: this.idToken.get()
				})

				if (this.idToken) this.apiClient = clientInstance

				return clientInstance[prop]
			}
		}
	)
}
