import React, { useEffect, useState, useRef } from "react"
import { timeAgo, dateToLocale } from "../utils"

export default ({ date, showRealTime }) => {
	const [formatted, setFormatted] = useState(timeAgo(date))
	const toLocale = dateToLocale(date) || ""
	let interval = useRef(0)

	useEffect(() => {
		const update = () => {
			if (formatted !== timeAgo(date)) setFormatted(timeAgo(date))
		}

		update()

		interval.current = setInterval(update, 30e3)

		return () => clearInterval(interval.current)
	}, [date, formatted])

	return (
		<span title={toLocale}>
			{formatted}
			{showRealTime && toLocale !== "" && (
				<span className="comment">{toLocale}</span>
			)}
		</span>
	)
}
