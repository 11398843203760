import { observable, action, comparer } from "mobx"

export default class ProductsStore {
	constructor(api) {
		this.api = api
	}

	get haveProducts() {
		return Array.isArray(this.products) && this.products.length
			? true
			: false
	}

	product(id) {
		if (!this.haveProducts) return false

		const product = this.products.find(p => p.id === id || p.uid === id)

		return product ? product : false
	}

	@observable products = []

	@action
	setProducts(products) {
		if (Array.isArray(products)) {
			if (!this.haveProducts) this.products = products
			else products.forEach(p => this.updateProduct(p.id, p))
		}
	}

	@action
	updateProduct(id, product) {
		// We have products, let's update the one
		if (this.haveProducts) {
			const i = this.products.findIndex(p => p.id === id || p.uid === id)

			// We already have this product, we just update it
			if (i > -1) {
				if (!comparer.structural(this.products[i], product))
					this.products[i] = Object.assign(this.products[i], product)
			} else {
				// We need to create this product
				this.products.push(product)
			}
		} else {
			this.products.push(product)
		}
	}

	getProducts(force = false) {
		if (!force && this.haveProducts) return Promise.resolve()

		return this.api.get(`/products`).then(response => {
			this.setProducts(response.data)
			return response.data
		})
	}

	getProduct(id, force = false) {
		if (!force) {
			const fromCache = this.product(id)
			if (fromCache && fromCache.full) return Promise.resolve(fromCache)
		}

		return this.api.get(`/product/${id}/full`).then(response => {
			if (response.data && response.data.id)
				this.updateProduct(response.data.id, response.data)

			return response.data
		})
	}

	setStock(id, size, oldStock, newStock) {
		return new Promise((resolve, reject) => {
			this.api
				.post(`/product/${id}/setStock`, {
					size: size,
					oldStock: oldStock,
					newStock: newStock
				})
				.then(response => {
					if (response.error) return resolve(response.message)

					if (response.data && response.data.updated)
						this.getProduct(id)
							.then(resolve)
							.catch(reject)
					else return resolve(false)
				})
				.catch(err => {
					if (
						err.response &&
						err.response.data &&
						err.response.data.message
					)
						return resolve(err.response.data.message)
					return reject(err)
				})
		})
	}
}
