import React, { useContext } from "react"
import { Context } from "../Auth"
import { Observer } from "mobx-react-lite"

export default props => {
	const auth = useContext(Context)

	return (
		<Observer>
			{() =>
				auth.authenticated
					? React.cloneElement(props.children, props)
					: null
			}
		</Observer>
	)
}
