import React from "react"

import ReactTable from "react-table"

export default props => {
	const {
		className,
		showPaginationTop,
		showPaginationBottom,
		...otherProps
	} = props

	const defaults = {
		defaultSortDesc: true,
		defaultPageSize: props.defaultPageSize || 20,
		get minRows() {
			if (!props.data || !props.data.length) return 5

			const dataLength = props.data.length - 1

			if (dataLength <= this.defaultPageSize) return dataLength
			// else if (dataLength > this.defaultPageSize) return Math.round(dataLength / this.defaultPageSize)
			else return 0
		},
		get showPaginationTop() {
			return (
				showPaginationTop !== false &&
				this.defaultPageSize < props.data.length
			)
		},
		get showPaginationBottom() {
			return (
				showPaginationBottom !== false &&
				this.defaultPageSize < props.data.length
			)
		},
		get className() {
			const extraClasses = new Set()

			if (className)
				className.split(" ").forEach(c => extraClasses.add(c))

			if (this.showPaginationTop) extraClasses.add("withPaginationTop")
			if (this.showPaginationBottom)
				extraClasses.add("withPaginationBottom")

			return Array.from(extraClasses.values()).join(" ")
		}
	}

	return (
		<ReactTable
			{...defaults}
			showPageSizeOptions={false}
			resizable={false}
			{...otherProps}
		/>
	)
}
