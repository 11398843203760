import React, { useEffect, useContext, useState } from "react"
import { Slider } from "@blueprintjs/core"
import { auth } from "../Auth"
import { Context } from "../Stores"
import { Observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "../Components/Grid"
import Table from "../Components/Table"
import TimeAgo from "../Components/TimeAgo"
import { thousandSeparator, capitalize } from "../utils"

export default () => {
	const { OrdersStore: o, WebSocket: ws } = useContext(Context)

	const handleWSEvents = event => {
		if (event === "order" || event === "orderUpdate") o.getOrders(true)
	}

	useEffect(() => {
		o.getOrders(true)

		ws.on("event", handleWSEvents)

		return () => ws.removeListener("event", handleWSEvents)
	})

	const Orders = () => {
		return (
			<Row style={{ marginTop: "10px" }}>
				<Col size="12">
					<Observer>
						{() => {
							const orders = o.orders.filter(
								o =>
									o.status === "Processed" ||
									o.status === "Pending"
							)

							if (!orders.length) return null

							return (
								<>
									<h3>
										Orders to process{" "}
										<span>These orders need attention</span>
									</h3>

									<Table
										className="ordersToProcess ordersTable"
										defaultPageSize={10}
										data={orders}
										defaultSorted={[
											{
												id: "completionDate",
												desc: true
											}
										]}
										columns={[
											{
												Header: "ID",
												sortMethod: (a, b, desc) => {
													// Anything undefined or weird to the bottom
													a =
														a === null ||
														a === undefined
															? -Infinity
															: a
													b =
														b === null ||
														b === undefined
															? -Infinity
															: b

													// force any string values to lowercase
													a =
														typeof a === "string"
															? a.toLowerCase()
															: a
													b =
														typeof b === "string"
															? b.toLowerCase()
															: b

													// Return either 1 or -1 to indicate a sort priority
													if (
														typeof a === "string" &&
														typeof b === "string"
													)
														if (!desc)
															return a.localeCompare(
																b
															) === 0
																? -1
																: 1
														else if (desc)
															return a.localeCompare(
																b
															) === 0
																? 1
																: -1

													return 0
												},
												accessor: "invoiceNumber",
												Cell: props => (
													<Link
														to={`/order/${props.original.token}`}
													>
														{
															props.original
																.invoiceNumber
														}
													</Link>
												)
											},
											{
												Header: "Status",
												accessor: "status",
												Cell: props => (
													<span
														className={props.original.status.toLowerCase()}
													>
														{props.original.status}
													</span>
												)
											},
											{
												Header: "Date",
												id: "orderDate",
												accessor: d =>
													new Date(d.completionDate),
												Cell: props => (
													<TimeAgo
														date={
															props.original
																.completionDate
														}
													/>
												)
											}
										]}
									/>
								</>
							)
						}}
					</Observer>
				</Col>
			</Row>
		)
	}

	const Analytics = () => {
		const [isUpdating, setIsUpdating] = useState(true)
		const [days, setDays] = useState(30)
		const [analytics, setAnalytics] = useState(false)

		// First day we got data in - March 20th
		const maxDays = Math.round(
			Math.abs(+new Date() - +new Date("Mar 20, 2019 00:00:00")) / 8.64e7
		)

		const DaysSlider = () => {
			return (
				<Slider
					disabled={isUpdating}
					initialValue={0}
					stepSize={1}
					min={1}
					max={maxDays < 365 ? maxDays : 365}
					onRelease={setDays}
					value={days}
				/>
			)
		}

		useEffect(() => {
			const updateStats = () => {
				setIsUpdating(true)

				auth.api
					.get(`/ganalytics/${days}`)
					.then(response => {
						setIsUpdating(false)
						setAnalytics(response.data)
					})
					.catch(err => {
						setIsUpdating(false)
						console.error(err)
					})
			}

			updateStats()
		}, [days])

		if (
			!analytics ||
			typeof analytics !== "object" ||
			!Object.keys(analytics).length
		)
			return <h3>Loading analytics...</h3>

		return (
			<Row>
				<Col size="12">
					<h3>
						Analytics{" "}
						<span>
							In the last {isUpdating ? "..." : analytics.daysAgo}{" "}
							{analytics.daysAgo > 1 ? "days" : "day"}
						</span>
					</h3>
					<DaysSlider />
					<table>
						<thead>
							<tr>
								<th className="center">Sessions</th>
								<th className="center">Users</th>
								<th className="center">Page Views</th>
								<th className="center">Bounce Rate</th>
								<th className="right">Session duration</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="center">
									{thousandSeparator(analytics.sessions)}
								</td>
								<td className="center">
									{thousandSeparator(analytics.users)}
								</td>
								<td className="center">
									{thousandSeparator(analytics.pageViews)}
								</td>
								<td className="center">
									{Math.round(analytics.bounceRate)} %
								</td>
								<td className="right">
									{Math.round(analytics.avgSessionDuration)}{" "}
									seconds
								</td>
							</tr>
						</tbody>
						<thead>
							<tr>
								<th className="left" colSpan="3">
									Source
								</th>
								<th className="right" colSpan="2">
									Sessions
								</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(analytics.sources)
								.slice(0, 5)
								.sort(
									(a, b) =>
										analytics.sources[b] -
										analytics.sources[a]
								)
								.map((sourceKey, k) => (
									<tr key={k}>
										<td colSpan="3">
											<b>{capitalize(sourceKey)}</b>
										</td>
										<td colSpan="2" className="right">
											{thousandSeparator(
												analytics.sources[sourceKey]
											)}{" "}
											<span className="comment">
												{Math.round(
													(analytics.sources[
														sourceKey
													] /
														analytics.sessions) *
														100
												)}{" "}
												%
											</span>
										</td>
									</tr>
								))}
						</tbody>
						<thead>
							<tr>
								<th className="center" colSpan="3">
									Country
								</th>
								<th className="right" colSpan="2">
									Sessions
								</th>
							</tr>
						</thead>
						<tbody>
							{analytics.countries.slice(0, 5).map((c, k) => {
								return (
									<tr key={k}>
										<td className="center" colSpan="3">
											<b>{c[0]}</b>
										</td>
										<td className="right" colSpan="2">
											{thousandSeparator(c[1])}
											<span className="comment">
												{Math.round(
													(c[1] /
														analytics.sessions) *
														100
												)}{" "}
												%
											</span>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</Col>
			</Row>
		)
	}

	return (
		<Container>
			<Row>
				<Col size="12">
					<h1>
						Dashboard <span>Move that shit</span>
					</h1>
				</Col>
			</Row>

			<Analytics />

			<Orders />
		</Container>
	)
}
