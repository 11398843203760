import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/nightOwl"

export default ({ code, language }) => {
	if (typeof code === "object") code = JSON.stringify(code, null, 2)

	return (
		<Highlight
			{...defaultProps}
			theme={theme}
			language={language}
			code={code}
		>
			{({ className, style, tokens, getLineProps, getTokenProps }) => (
				<pre className={className} style={style}>
					{tokens.map((line, i) => (
						<div
							{...getLineProps({
								line,
								key: i
							})}
						>
							{line.map((token, key) => (
								<span
									{...getTokenProps({
										token,
										key
									})}
								/>
							))}
						</div>
					))}
				</pre>
			)}
		</Highlight>
	)
}
