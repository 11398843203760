import { observable, action, computed } from "mobx"

export default class OrdersStore {
	constructor(api) {
		this.api = api

		this.setSearchInput = this.setSearchInput.bind(this)
	}

	@observable orders = []
	@observable searchInput = false

	get haveOrders() {
		return Array.isArray(this.orders) && this.orders.length ? true : false
	}

	@computed
	get sortedOrders() {
		if (!this.haveOrders) return false

		return this.orders
			.slice()
			.sort(
				(a, b) =>
					new Date(b.modificationDate) - new Date(a.modificationDate)
			)
	}

	@computed
	get filteredOrders() {
		if (!this.haveOrders) return false
		const input = this.searchInput

		if (!input) return this.orders

		return this.orders
			.slice()
			.filter(order => {
				if (order.shippingAddressName.toLowerCase().includes(input))
					return true
				if (order.user.email.toLowerCase().includes(input)) return true
				if (order.user.id.includes(input)) return true
				if (order.invoiceNumber.toLowerCase().includes(input))
					return true
				if (order.status.toLowerCase().includes(input)) return true

				return false
			})
			.sort(
				(a, b) =>
					new Date(b.modificationDate) - new Date(a.modificationDate)
			)
	}

	@action
	setSearchInput(input) {
		if (typeof input !== "string") this.searchInput = false
		else this.searchInput = input.trim().toLowerCase()
	}

	findOrder(token, keyOnly = false) {
		const find = order => order.token && order.token === token

		if (this.haveOrders)
			if (keyOnly) return this.orders.findIndex(find)
			else return this.orders.find(find)

		return false
	}

	findOrderByCustomer(id) {
		const find = order =>
			order.user && order.user.id && order.user.id === id

		if (this.haveOrders) return this.orders.filter(find)

		return false
	}

	getOrders(force = false) {
		if (!force && this.haveOrders) return Promise.resolve(this.orders)

		return this.api
			.get(`/orders`)
			.then(response => {
				this.setOrders(response.data.items)
				return response.data.items
			})
			.catch(() => false)
	}

	getOrder(token, force = false) {
		const cachedOrder = this.findOrder(token)
		if (cachedOrder && !force) return Promise.resolve(cachedOrder)

		return this.api
			.get(`/order/${token}`)
			.then(response => {
				this.setOrder(response.data)
				return response.data
			})
			.catch(() => false)
	}

	@action
	setOrders(orders) {
		if (Array.isArray(orders)) orders.forEach(order => this.setOrder(order))
	}

	@action
	setOrder(order) {
		if (!order.token) return

		const updateOrder = this.findOrder(order.token, true)

		if (updateOrder !== false && updateOrder > -1)
			this.orders[updateOrder] = order
		else if (Array.isArray(this.orders)) this.orders.push(order)
	}
}
