import React, { memo } from "react"
import { currency, thousandSeparator } from "../utils"

export default memo(({ amount, currency: code = "eur" }) => {
	if (!amount) return null
	if (typeof code !== "string") code = "eur"

	code = code.toLowerCase().trim()

	const symbol = currency(code)

	amount = thousandSeparator(amount, " ", 0)

	return (
		<span className="money">
			{amount} {symbol}
		</span>
	)
})
