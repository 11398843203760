import { useEffect } from "react"
import auth0 from "auth0-js"
import { clientID, domain } from "./config.json"

export default () => {
	useEffect(() => {
		const A = new auth0.WebAuth({
			clientID: clientID,
			domain: domain
		})
		A.crossOriginVerification()
	})

	return null
}
