import React from "react"

export const Container = props => {
	const { className } = props
	return (
		<div
			className={[
				props.fluid ? "container-fluid" : "container",
				className
			].join(" ")}
		>
			{props.children}
		</div>
	)
}

export const Row = props => {
	const { className, style } = props
	return (
		<div className={["row", className].join(" ")} style={style}>
			{props.children}
		</div>
	)
}

export const Col = props => {
	const { xs, sm, md, lg, size, className } = props
	const biggest = Math.max.apply(
		null,
		[xs, sm, md, lg, size].map(s => (s === undefined ? 0 : parseInt(s)))
	)
	const fallback = size ? size : biggest

	let classes = []

	if (xs) classes.push(`col-xs-${parseInt(xs)}`)
	else classes.push(`col-xs-${parseInt(fallback)}`)

	if (sm) classes.push(`col-sm-${parseInt(sm)}`)
	else classes.push(`col-sm-${parseInt(fallback)}`)

	if (md) classes.push(`col-md-${parseInt(md)}`)
	else classes.push(`col-md-${parseInt(fallback)}`)

	if (lg) classes.push(`col-lg-${parseInt(lg)}`)
	else classes.push(`col-lg-${parseInt(fallback)}`)

	// Push props classes to the end
	classes.push(className)

	return <div className={classes.join(" ")}>{props.children}</div>
}
