import React, { useEffect, useContext, Fragment } from "react"
import { Observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "../Components/Grid"
import CustomerAddress from "../Components/CustomerAddress"
import PhoneNumber from "../Components/PhoneNumber"
import Money from "../Components/Money"
import OrderDetails from "../Components/DeployableCode"
import TimeAgo from "../Components/TimeAgo"
import { Context } from "../Stores"

export default props => {
	const { match } = props
	const { OrdersStore: o, ProductsStore: p, WebSocket: ws } = useContext(
		Context
	)

	useEffect(() => {
		o.getOrder(match.params.token, true)

		p.getProducts()
	}, [o, p, match.params.token]) // The page only runs this effect once

	const SummaryTable = ({ order }) => {
		if (!order || typeof order !== "object") return null

		const Discounts = ({ discounts }) => {
			if (!discounts || !Array.isArray(discounts)) return null

			return discounts.map(discount => {
				return (
					<tr>
						<td>
							{discount.name}
							<span className="comment">
								{discount.type} - {discount.trigger}
								
							</span>
						</td>
						<td colSpan="3">
							{!discount.amountSaved ? null : (
								<Money
									amount={discount.amountSaved * -1}
									currency={discount.currency}
								/>
							)}
						</td>
					</tr>
				)
			})
		}

		return (
			<Fragment>
				<h3>Order Summary</h3>
				<table>
					<thead>
						<tr>
							<th>Status</th>
							<th>Total</th>
							<th>Shipping Method</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{order.status}</td>
							<td>
								<Money
									amount={order.finalGrandTotal}
									currency={order.currency}
								/>
								<span className="comment">
									VAT:{" "}
									<Money
										amount={order.taxes
											.filter(
												tax =>
													tax.taxName &&
													tax.taxName
														.toLowerCase()
														.includes("tva")
											)
											.map(tax => tax.amount)}
										currency={order.currency}
									/>
								</span>
							</td>
							<td>{order.shippingMethod}</td>
							<td>
								<TimeAgo
									date={order.completionDate}
									showRealTime
								/>
							</td>
						</tr>
						<Discounts discounts={order.discounts} />
					</tbody>
				</table>
			</Fragment>
		)
	}

	// The little widget rendering the item's picture, name, link & stock in the item's list
	const ItemDetailsWidget = ({ item, size }) => (
		<Observer>
			{() => {
				size = typeof size !== "string" ? "" : size.toLowerCase().trim()

				const renderItem = ({ img: Img = null, name: Name = null }) => (
					<Fragment>
						<td>
							{Img ? (
								<Img />
							) : (
								<img src={item.image} alt={item.name} />
							)}
						</td>
						<td className="left">{Name ? <Name /> : item.name}</td>
					</Fragment>
				)

				const product = p.product(item.id)
				if (!product || !product.full)
					return renderItem({
						img: null,
						name: null
					})

				const StockComment = () => {
					let inStockRightNow = product.stock.map(s => s.stock)

					let inStockExactSizeRightNow = product.stock
						.filter(s => {
							try {
								return s.name.toLowerCase() === size
							} catch (err) {
								return false
							}
						})
						.map(s => s.stock)

					inStockRightNow = inStockRightNow.length
						? inStockRightNow.reduce((a, b) => a + b)
						: false

					inStockExactSizeRightNow = inStockExactSizeRightNow.length
						? inStockExactSizeRightNow.reduce((a, b) => a + b)
						: false

					const renderer = msg => (
						<span className="comment">{msg}</span>
					)

					if (inStockRightNow && size === "o/s")
						return renderer(
							`${inStockExactSizeRightNow} in stock right now`
						)
					else if (inStockRightNow && inStockExactSizeRightNow)
						return renderer(
							`${inStockExactSizeRightNow} ${size} in stock - ${inStockRightNow} in total`
						)
					else if (inStockRightNow && !inStockExactSizeRightNow)
						return renderer(
							`Size is sold out, ${inStockRightNow} others in stock`
						)
					else return renderer(`Sold out`)
				}

				return renderItem({
					img: () => (
						<img
							src={product.full.data.transparent_image.url}
							alt={item.name}
						/>
					),
					name: () => (
						<Fragment>
							<Link to={`/product/${product.id}`}>
								{item.name}
							</Link>
							<StockComment />
						</Fragment>
					)
				})
			}}
		</Observer>
	)

	const ItemsTable = ({ items }) => {
		// Trying to make only 1 request / item that needs to be fetched
		const itemIDS = [...new Set(items.map(item => item.id))]
		itemIDS.forEach(id => p.getProduct(id))

		// Only on mount / unmount
		useEffect(() => {
			// Force a refresh on stock event
			const handleWSEvents = event => {
				// We got a new order, update stocks
				if (event === "order")
					itemIDS.forEach(id => p.getProduct(id, true))

				// We updated an order, update this one
				if (event === "orderUpdate")
					o.getOrder(match.params.token, true)
			}

			ws.on("event", handleWSEvents)
			return () => ws.removeListener("event", handleWSEvents)
		}, [itemIDS])

		// Rule of hooks dictate that you musn't return before using a hook ...
		if (!Array.isArray(items) || !items.length) return null

		return (
			<Fragment>
				<h3>
					Items{" "}
					<span>
						{items.length > 1
							? `${items.length} items`
							: `${items.length} item`}
					</span>
				</h3>
				<table>
					<thead>
						<tr>
							<th className="left" colSpan="2">
								Item
							</th>
							<th>Size</th>
						</tr>
					</thead>
					<tbody>
						{items.map((item, i) => {
							const size = item.customFields.find(
								field => field.name.toLowerCase() === "size"
							).value

							return (
								<tr key={i}>
									<ItemDetailsWidget
										item={item}
										size={size}
									/>
									<td>{size}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</Fragment>
		)
	}

	const CustomerTable = ({ order }) => {
		const { user: customer } = order

		if (!customer || typeof customer !== "object") return null

		const ordersFromCustomer = o.orders.filter(
			order => order.user.id === customer.id
		)

		const totalSpent = ordersFromCustomer.length
			? ordersFromCustomer
					.map(order => order.finalGrandTotal)
					.reduce((a, b) => a + b)
			: 0

		const averageSpent = totalSpent
			? totalSpent / ordersFromCustomer.length
			: 0

		const firstOrderDate = ordersFromCustomer.length
			? ordersFromCustomer.sort(
					(a, b) =>
						new Date(b.creationDate) - new Date(a.creationDate)
			  )[ordersFromCustomer.length - 1].creationDate
			: false

		return (
			<Fragment>
				<h3>
					Customer{" "}
					<span>
						{ordersFromCustomer.length}
						{` orders - ${customer.id}`}
					</span>
				</h3>
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Address</th>
							<th>Phone</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<Link to={`/customer/${customer.id}`}>
									{customer.shippingAddressName}
								</Link>
							</td>
							<td>{customer.email}</td>
							<td>
								<CustomerAddress order={order} isOrder />
							</td>
							<td>
								<PhoneNumber
									number={customer.shippingAddressPhone}
								/>
							</td>
						</tr>
					</tbody>

					<thead>
						<tr>
							<th># Orders</th>
							<th>Total spent</th>
							<th>Average spent</th>
							<th>Customer since</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								{ordersFromCustomer.length}{" "}
								{ordersFromCustomer.length > 1
									? "orders"
									: "order"}
							</td>
							<td>
								<Money amount={totalSpent} />
							</td>
							<td>
								<Money amount={averageSpent} />
							</td>
							<td>
								{firstOrderDate
									? new Date(
											firstOrderDate
									  ).toLocaleDateString()
									: "Never"}
							</td>
						</tr>
					</tbody>
				</table>
			</Fragment>
		)
	}

	return (
		<Observer>
			{() => {
				const order = o.findOrder(match.params.token)
				if (!order) return null

				// If we only have this one order, fetch others too
				// This will happen if you load this page directly without loading the orders list first
				// if (o.orders.length === 1) o.getOrders(true)

				return (
					<Container className="order">
						<Row>
							<Col size="12">
								<h1>
									Order <span>{order.invoiceNumber}</span>
								</h1>
							</Col>
						</Row>

						<Row>
							<Col size="12" className="orderTableWrapper">
								<SummaryTable order={order} />
							</Col>
							<Col size="12" lg="4" className="orderTableWrapper">
								<ItemsTable items={order.items} />
							</Col>
							<Col size="12" lg="8" className="orderTableWrapper">
								<CustomerTable order={order} />
							</Col>
						</Row>

						<Row className="orderDetails">
							<Col size="12">
								<OrderDetails payload={order} />
							</Col>
						</Row>
					</Container>
				)
			}}
		</Observer>
	)
}
