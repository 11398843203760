import React from "react"
import { Link } from "react-router-dom"
import Money from "./Money"
import Table from "./Table"
import TimeAgo from "./TimeAgo"

export default ({ orders }) => (
	<Table
		className="ordersTable"
		showPaginationTop={false}
		defaultSorted={[{ id: "orderDate", desc: true }]}
		noDataText="No orders found"
		data={orders}
		columns={[
			{
				Header: "ID",
				sortMethod: (a, b, desc) => {
					// Anything undefined or weird to the bottom
					a = a === null || a === undefined ? -Infinity : a
					b = b === null || b === undefined ? -Infinity : b

					// force any string values to lowercase
					a = typeof a === "string" ? a.toLowerCase() : a
					b = typeof b === "string" ? b.toLowerCase() : b

					// Return either 1 or -1 to indicate a sort priority
					if (typeof a === "string" && typeof b === "string")
						if (!desc) return a.localeCompare(b) === 0 ? -1 : 1
						else if (desc) return a.localeCompare(b) === 0 ? 1 : -1

					return 0
				},
				accessor: "invoiceNumber",
				Cell: props => (
					<Link to={`/order/${props.original.token}`}>
						{props.original.invoiceNumber}
					</Link>
				)
			},
			{
				Header: "Customer",
				accessor: "shippingAddressName",
				Cell: props => (
					<Link to={`/customer/${props.original.user.id}`}>{props.value}</Link>
				)
			},
			{
				Header: "Amount",
				accessor: "finalGrandTotal",
				Cell: props => (
					<Money
						amount={props.original.finalGrandTotal}
						currency={props.original.currency}
					/>
				)
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => (
					<span className={props.original.status.toLowerCase()}>
						{props.original.status}
					</span>
				)
			},
			{
				Header: "Items",
				id: "itemsCount",
				accessor: d => d.items.length
			},
			{
				Header: "Date",
				id: "orderDate",
				accessor: d => new Date(d.completionDate),
				Cell: props => <TimeAgo date={props.original.completionDate} />
			}
		]}
	/>
)
