import React, { useEffect, useContext } from "react"
import { Observer } from "mobx-react-lite"
import { Context } from "../Stores"
import { Container, Row, Col } from "../Components/Grid"
import ProductsTable from "../Components/ProductsTable"
import { log } from "../utils"

const l = log("Component:Products")

export default props => {
	const { ProductsStore: p, WebSocket: ws } = useContext(Context)
	const getProducts = (force = false) => {
		p.getProducts(force).catch(l.error)
	}
	const handleWSEvents = event => {
		if (event === "stockUpdate") getProducts(true)
	}

	useEffect(() => {
		getProducts(true)

		ws.on("event", handleWSEvents)
		return () => ws.removeListener("event", handleWSEvents)

		/** NO NEED FOR POLLING SINCE WE HAVE WS - KEEPING IT JUST IN CASE.... */
		// const updateProducts = setInterval(() => {
		// 	getProducts(true)
		// }, 5000)

		// return () => clearInterval(updateProducts)
	})

	return (
		<Observer>
			{() => (
				<Container>
					<Row>
						<Col size={12}>
							<h1>
								Products
								<span>{p.products.length} products</span>
							</h1>

							<ProductsTable products={p.products} />
						</Col>
					</Row>
				</Container>
			)}
		</Observer>
	)
}
